import { BaseEntity } from './base-entity.models';
import { ChecklistEntry } from './checklist.models';
import { Session } from './session.models';
import { Child } from './child.models';
import { Parent } from './parent.models';
import { SubmissionBreak } from '@core/constants';
import { ApiError } from '../http.models';
import { Setting } from './settings.models';

export enum SessionAppointmentStatus {
  REQUESTED = 'Requested',
  WAITLIST = 'Waitlist',
  SCHEDULING = 'Scheduling',
  INVITE_SENT = 'Invite Sent',
  INVITE_ACCEPTED = 'Invite Accepted',
  DECLINED = 'Declined',
  BILLING = 'Billing',
  CONFIRMED = 'Confirmed',
  COMPLETED = 'Completed',
  PAPERWORK = 'Paperwork',
  CANCELLED = 'Canceled',
}

export interface ChangeSessionAppointmentsStatusPayload {
  id: string[];
  status: SessionAppointmentStatus;
  reason?: string[];
}
export interface ChangeSessionStatusPayload {
  status: SessionAppointmentStatus;
  reason?: string[];
}

export interface SessionAppointment extends BaseEntity {
  session: Session;
  status: SessionAppointmentStatus;
  break: SubmissionBreak;
  preferred_start_time: null | {
    time_from: string | null;
    time_to: string | null;
  };
  hours_per_day: number;
  checklist: ChecklistEntry[];
  prev_status: string;
  child: Child;
  parent: Parent;
  reason?: string[];
}

export interface ChecklistItem extends BaseEntity {
  checked: false;
  item: ChecklistItemContent;
  updated_at: string | null;
  due_date: string | null;
  value: unknown;
}

export interface ChecklistItemContent extends BaseEntity {
  title: string;
  sign_in_reminder: boolean;
  type: string;
  order: 0;
}

export type AssignChildToAppointmentError = ApiError<{ name: Setting; _id: string } | null>;
