import { Injectable } from '@angular/core';
import { QueryParams } from '@core/models/query-params.models';
import { PaginatedData } from '@core/models/paginated-data.models';
import {
  ChangeSessionAppointmentsStatusPayload,
  SessionAppointment,
} from '@core/models/entities/session-appointment.models';
import { RegistrationFormPayload } from '@core/models/entities/registration-form.models';
import { HttpApi } from './http.api';
import { Observable } from 'rxjs';

const featurePrefix: string = 'session';

const featureSuffix: string = 'appointments';

const feature: string = `${featurePrefix}/${featureSuffix}`;

@Injectable({ providedIn: 'root' })
export class SessionAppointmentsApi {
  constructor(private readonly httpApi: HttpApi) {}

  getOne(id: string): Observable<SessionAppointment> {
    return this.httpApi.get(`${feature}/${id}`);
  }

  getMyPaginated(params: QueryParams): Observable<PaginatedData<SessionAppointment>> {
    return this.httpApi.get(`${feature}/my`, { params });
  }

  getPaginatedById(id: string, params: QueryParams): Observable<PaginatedData<SessionAppointment>> {
    return this.httpApi.get(`${featurePrefix}/${id}/${featureSuffix}`, { params });
  }

  getPaginatedByParentId(id: string, params: QueryParams): Observable<PaginatedData<SessionAppointment>> {
    return this.httpApi.get(`${feature}/parent/${id}`, { params });
  }

  changeStatus(sessionId: string, payload: ChangeSessionAppointmentsStatusPayload): Observable<SessionAppointment[]> {
    return this.httpApi.patch(`${feature}/${sessionId}/status`, payload);
  }

  addPatient(id: string, patient: string): Observable<SessionAppointment> {
    return this.httpApi.post(`${featurePrefix}/${id}/${featureSuffix}/child`, { patient });
  }

  movePatient(patient: string, from_session: string, to_session: string): Observable<SessionAppointment> {
    return this.httpApi.post(`${featurePrefix}/${featureSuffix}/child/move`, { patient, from_session, to_session });
  }

  applyForm(payload: RegistrationFormPayload): Observable<SessionAppointment> {
    return this.httpApi.post(`${feature}`, payload);
  }
}
