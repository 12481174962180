import { ChangeDetectionStrategy, Component, Inject, Signal, WritableSignal, signal, viewChild } from '@angular/core';
import { SessionAppointment, SessionAppointmentStatus } from '@core/models/entities/session-appointment.models';
import { QueryParams, PaginatedData, WlcmAutocompleteOption, WlcmOption } from '@wlcm/angular/core';
import { SessionAppointmentsApi } from '@core/services/api/session-appointments.api';
import { SESSION_TIME_OPTIONS } from '@core/constants/date-options.constants';
import { EditKidDialogConfig } from '../../models/assign-kid.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionEvent } from '@core/models/entities/session.models';
import { addHour, createSchedulerEvent, parseTimePoint, subtractHour } from '../../utils/session-scheduler.utils';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { BehaviorSubject, EMPTY, Observable, finalize, map, of, switchMap, tap } from 'rxjs';
import { EventsApi } from '@core/services/api/events.api';
import { NapaEventPayload, UpdateNapaEventPayload } from '@core/models/entities/event.models';
import { GridAdapter } from '@shared/modules/scheduler/models/grid-adaper/grid-adaper.models';
import { WlcmAutocompleteComponent, WlcmAutocompleteValue, WlcmSelectComponent } from '@wlcm/angular/forms';
import { SessionSchedulerApi } from '../../services/session-scheduler.api';
import { QueryFilters } from '@core/models/query-params.models';
import { Child } from '@core/models/entities/child.models';
import { DialogApi } from '@shared/modules/dialog/services/dialog.api';
import { NapaDialogCloseEvent } from '@shared/modules/dialog/models/dialog.models';
import { MODALITY_MISMATCH_DIALOG_CONFIG } from '../../constants/modality-mismatch.constants';

@Component({
  selector: 'napa-edit-kid-dialog',
  templateUrl: './edit-kid-dialog.component.html',
  styleUrl: './edit-kid-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditKidDialogComponent {
  form: FormGroup = this.createForm();

  readonly startTimeOptions: WlcmOption[] = SESSION_TIME_OPTIONS.filter((option: WlcmOption) => {
    const { hours, minutes } = parseTimePoint(option.value as string);

    return hours + minutes * 0.01 <= 18;
  });

  readonly endTimeOptions: WlcmOption[] = SESSION_TIME_OPTIONS.filter((option: WlcmOption) => {
    const { hours, minutes } = parseTimePoint(option.value as string);

    return hours >= 9 && hours + minutes * 0.01 <= 19;
  });

  readonly modalityOptions: WritableSignal<WlcmOption[]> = signal([]);

  readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly appointmentsFilters: QueryFilters = {
    status: {
      noneOf: [
        SessionAppointmentStatus.WAITLIST,
        SessionAppointmentStatus.DECLINED,
        SessionAppointmentStatus.CANCELLED,
      ],
    },
  };

  endTimeSelect: Signal<WlcmSelectComponent> = viewChild.required('endTime', { read: WlcmSelectComponent });
  sessionNameAutocomplete: Signal<WlcmAutocompleteComponent> = viewChild.required('sessionNameAutocomplete');

  constructor(
    private eventsApi: EventsApi,
    private dialogApi: DialogApi,
    private formBuilder: FormBuilder,
    private gridAdapter: GridAdapter,
    private sessionSchedulerApi: SessionSchedulerApi,
    private sessionAppointmentsApi: SessionAppointmentsApi,
    private dialogRef: MatDialogRef<EditKidDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private config: EditKidDialogConfig
  ) {}

  submit(): void {
    if (this.form.invalid) return this.form.markAllAsTouched();

    const dataToSend: UpdateNapaEventPayload = {
      time_from: this.payload.time_from,
      time_to: this.payload.time_to,
      therapist: this.config.data.event._id,
      modality: this.payload.modality,
    };

    this.validateModalityMismatch()
      .pipe(
        switchMap((isValid: boolean) => {
          if (isValid) {
            this.loading$.next(true);
            return this.eventsApi.update(this.config.data.eventId, dataToSend).pipe(
              tap((res) => {
                const current = createSchedulerEvent(res, this.config.data.event._id);
                this.gridAdapter.updateOne(this.config.data.eventId, { ...current });
              })
            );
          }

          return EMPTY;
        })
      )
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe(() => {
        this.sessionSchedulerApi.notifyChildAssignment();

        this.dialogRef.close();
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  fetchKidOptions = (params: QueryParams): Observable<PaginatedData<WlcmAutocompleteOption>> => {
    const filters: QueryFilters = this.appointmentsFilters;

    return this.sessionAppointmentsApi.getPaginatedById(this.config.data.sessionId, { ...params, filters }).pipe(
      map((paginatedData: PaginatedData<SessionAppointment>) => {
        return {
          ...paginatedData,
          data: paginatedData.data.map((appointment: SessionAppointment) => {
            return new WlcmAutocompleteOption(
              appointment.child._id,
              appointment.child.full_name,
              appointment.child.full_name,
              appointment.child
            );
          }),
        };
      }),
      tap((res) => {
        this.handleSelectedChild(res.data[0]);
        this.modalityControl.patchValue([...(this.config.data.clientEvent?.event?.modality ?? [])]);
      })
    );
  };

  handleStartTimeChange({ value }: MatSelectChange): void {
    this.endTimeControl.setValue(addHour(value));
  }

  handleEndTimeChange({ value }: MatSelectChange): void {
    this.startTimeControl.setValue(subtractHour(value));
  }

  handleSelectedChild(value: WlcmAutocompleteValue): void {
    if (value instanceof Object) {
      const child: Child = (value as WlcmAutocompleteOption).data as Child;

      const modalities: string[] = child.recommendations[0]?.modalities || [];

      this.modalityControl.enable();

      return this.modalityOptions.set(this.generateModalityOptions(modalities));
    }

    this.modalityControl.reset();

    this.modalityControl.disable();

    this.modalityOptions.set([]);
  }

  private validateModalityMismatch(): Observable<boolean> {
    const sessionEvent: SessionEvent = this.config.data.event;

    const selectedModalities: string[] = this.modalityControl.value;

    const hasModalityMismatch: boolean = !selectedModalities.every((childModality: string) => {
      return sessionEvent.modalities.some((therapistModality: string) => therapistModality === childModality);
    });

    return hasModalityMismatch ? this.requestModalityMismatchConfirmation() : of(true);
  }

  private requestModalityMismatchConfirmation(): Observable<boolean> {
    return this.dialogApi
      .openGenericPopup(MODALITY_MISMATCH_DIALOG_CONFIG)
      .afterClosed()
      .pipe(map((event: NapaDialogCloseEvent) => !!event?.primaryBtnClicked));
  }

  get payload(): NapaEventPayload {
    const payload: NapaEventPayload = this.form.getRawValue();

    return { ...payload, child: this.childControl.value.value };
  }

  get sessionEvent(): SessionEvent {
    return this.config.data.event;
  }

  get childControl(): FormControl {
    return this.form.controls['child'] as FormControl;
  }

  get modalityControl(): FormControl {
    return this.form.controls['modality'] as FormControl;
  }

  get startTimeControl(): FormControl {
    return this.form.controls['time_from'] as FormControl;
  }

  get endTimeControl(): FormControl {
    return this.form.controls['time_to'] as FormControl;
  }

  private generateModalityOptions(modalities: string[]): WlcmOption[] {
    return modalities.map((modality: string) => new WlcmOption(modality, modality));
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      child: [{ value: this.config.data.clientEvent?.event?.child?.full_name, disabled: true }, [Validators.required]],
      modality: [
        { value: [...(this.config.data.clientEvent?.event?.modality ?? [])], disabled: true },
        [Validators.required],
      ],
      therapist: [this.config.data.event._id, [Validators.required]],
      time_from: [this.config.data.start, [Validators.required]],
      time_to: [this.config.data.end, [Validators.required]],
    });
  }
}
